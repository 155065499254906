import axios from "../utils/axios";

export const createInvoice = async (amount, charityId, name, email) => {
  const {
    data: { response, error },
  } = await axios.post(
    `/.netlify/functions/fetch-stripe-invoice`,
    { amount, name, email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return { error, response };
};
