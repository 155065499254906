import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import Vimeo from "@u-wave/react-vimeo";
import titleLogo from "./images/title-image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <img src={titleLogo}></img>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <center>
                    <Vimeo video="747814711" autoplay />
                  </center>
                </div>
                <div className="section-main-description">
                  <p>
                    The OCC Hacienda Employee Fund is a permanent charitable
                    entity established by the membership after recognizing that
                    unforeseen financial crises for our employees can occur at
                    any time and be devastating. Your generosity will support
                    our employees and their families when they need it most.
                  </p>
                  <p>
                    Aside from Givinga's minimal administrative cost and credit
                    card processing fees (if that donation method is preferred
                    and associated fees aren't donated), all proceeds will go
                    directly to our employees. Member donations and employee
                    grants will be handled confidentially. All donations are
                    tax-deductible.
                  </p>
                  <p>Thank you for your generosity.</p>
                  <p>- OCC Hacienda Employee Fund Committee</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
